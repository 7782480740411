import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import TelegramIcon from "../assets/svg/TelegramIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/svg/logo_new.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import { useSelector } from "react-redux";
import BeforeLeftBar from "../views/components/BeforeLeftBar";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";

import { BiSearch } from "react-icons/bi";

import CasinoTabsIcon from "../assets/images/icons/sidebaricon/blackjack.png";
import sportsTabsIcon from "../assets/images/icons/sidebaricon/football.png";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const { appDetails } = useSelector((state) => state.app);

  const handleRedirection = (redirectionUrl, tabName) => {
    if (redirectionUrl) {
      localStorage.setItem("isSideBarOpen", true);
      if (tabName) {
        navigate(redirectionUrl, {
          state: {
            tabName: tabName ? tabName : "",
          },
        });
      } else {
        navigate(redirectionUrl);
      }
    }
  };

  return (
    <header className="header beforeheader">
      <div className="top_head w-100">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-md-between align-items-center">
                <div className="d-flex align-items-center me-auto me-md-0">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="casino-sportsheader d-none d-md-block">
                    <ul>
                      <li
                        // onClick={() =>
                        //   handleRedirection("/casinogame", "Live Casino")
                        // }
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        <img src={CasinoTabsIcon} />
                        Casino
                      </li>
                      <li
                        className="active"
                        onClick={() =>
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        }
                      >
                        <img src={sportsTabsIcon} />
                        Sports
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex  align-items-center ">
                  <Button
                    variant="primary"
                    onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}
                    className="button-primary btn_signin"
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                    }
                    className="button-primary btn_signup ms-2"
                  >
                    Join Now
                  </Button>
                </div>
                <div className="d-flex sidebarBtn">
                  {/* <BeforeLeftBar /> */}
                </div>
                <div className="middle-part d-none">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link
                            href="/sportsbook?bt-path=%2Flive"
                            className="exchange-nav"
                          >
                            Live Betting
                          </Nav.Link>
                          <Nav.Link href="/" className="exchange-nav">
                            Sportsbook
                          </Nav.Link>
                          <Nav.Link href="/sign-in">Slots</Nav.Link>
                          <Nav.Link href="/sign-in">Casino</Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <div className="searchicon ms-2">{/* <BiSearch /> */}</div>
                  <ul className="social d-none">
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </header>
  );
};

export default Header;
